<ds-app-wrapper-v2 disablePageHeadlines>
  <ds-breadcrumbs
    fxFlex="0 0 auto"
    [baseUrl]="'/'"
    [baseLabel]="'Feasibility study'"
  ></ds-breadcrumbs>
  <div fxFlex="1 1 auto" class="flex-page-container">
    <router-outlet></router-outlet>
  </div>
</ds-app-wrapper-v2>
